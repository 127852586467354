.FormInput {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    width: 300px;
}

input {
    padding: 15px;
    margin: 10px 0;
    border-radius: 5px;
    border: 1px solid lightgray;
}

label {
    font-size: 12px;
    color: rgb(134, 137, 163);
}

span {
    font-size: 12;
    color: red;
    padding: 2.5px;
    display: none;
}

input:invalid[focused="true"] {
    border: 1px solid red;
}

input:invalid[focused="true"] ~ span {
    display: block;
}

input[type="date"] {
    display: block;
    -webkit-appearance: textfield;
    -moz-appearance: textfield;
    min-height: 1.2em;
}

@media (min-width: 2560px) {
    .FormInput {
        width: 90%;
        margin: auto;
        font-size: 1.5px;
    }

    label {
        font-size: 1.5rem;
    }

    input {
        font-size: 1.5rem;
    }

    span {
        font-size: 1rem;
        display: none;
    }
}
