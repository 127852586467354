.App {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 100vh;
    background-image: url(https://images.unsplash.com/photo-1528460033278-a6ba57020470?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=735&q=80);
    background-position: center;
    background-size: cover;
}

form {
    background-color: #fff;
    height: 65%;
    padding: 0 60px;
    border-radius: 10px;
}

h1 {
    color: rgb(39, 42, 70);
    text-align: center;
}

button {
    width: 100%;
    height: 50px;
    padding: 10px;
    border: none;
    background-color: rgb(39, 42, 70);
    color: #fff;
    font-size: 18px;
    font-weight: bold;
    border-radius: 5px;
    margin: 15px 0 30px 0;
    cursor: pointer;
}

button:active {
    transform: scale(0.98);
}

.SignUpMsg {
    background-color: #fff;
    color: green;
    text-align: center;
    padding: 5px;
    border-radius: 5px;
    height: 130px;
    width: 250px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.SignUpMsg button {
    width: 60%;
    align-self: center;
}

@media (max-width: 600px) {
    .App {
        margin: 0;
        width: auto;
        background-image: none;
        justify-content: flex-start;
        height: 100vh;
    }

    h1 {
        font-size: 2.5rem;
    }
    form {
        margin: 20px auto;
        padding: 0;
    }
}

@media (min-width: 2560px) {
    h1 {
        font-size: 3rem;
    }

    form {
        width: 18%;
    }

    button {
        font-size: 23px;
        height: 70px;
    }
}
